<template>
  <div class="submitted">
    <div class="submitted-wrap">
      <img :src="imgWaiting" class="waiting-img">
      <div class="status">您已成功提交加盟协议</div>
      <div class="desc">我们工作人员在收到您的申请后会尽快处理，请耐心等候~</div>
    </div>
    <van-button @click="backClick" class="back">返回首页</van-button>
    <van-button @click="seeClick">查看协议</van-button>
  </div>
</template>

<script>
import common from "../../utils/common";
import {appAction, uwStatisticAction} from "../../utils/util";
import {getJoinApply, getContractByNo} from '@/utils/api'
export default {
  data() {
    return {
      imgWaiting: require('@/assets/img/mine/successIcon.png'),
    }
  },
  mounted() {
    uwStatisticAction('/joincksuccess','加盟村口成功')
  },
  methods:{
    backClick(){
      this.$router.push({name: 'homePage'})
    },
    seeClick() {
      getJoinApply({token: common.getItem('wtToken'),}).then(res=>{
        if(res.code === 200){
          if(res.data && res.data.businessCode == 200){
            if(res.data.data.electronContractNo){
              getContractByNo({
                electronContractNo: res.data.data.electronContractNo
              }).then(res=>{
                window.location.href = res.data.data
              })
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.submitted{
  height: 100%;
  background: #f5f5f5;
  font-size: 0.3rem;
  text-align: center;
  margin-top: 1rem;
  .submitted-wrap{
    background: #fff;
  }
  .waiting-img{
    width: 1.28rem;
    height: 1.28rem;
  }
  .status{
    margin-top: 0.4rem;
    font-size: 0.36rem;
    color: #333333;
  }
  .desc{
    color: #999;
    font-size: 0.26rem;
    padding: 0.16rem 0.24rem 0.64rem;
  }
  .back{
    margin-top: 0.4rem !important;
    background: #3F7C53 !important;
    color: #fff !important;
  }
  .van-button{
    margin-top: 0.24rem;
    font-size: 0.36rem;
    width: 5.6rem;
    height: 0.98rem;
    border: 0.01rem solid #3F7C53;
    color: #3F7C53;
    background: #fff;
  }
}
</style>
